import GoogleService from "./GoogleService";
import GoogleAnalyticsService from "./GoogleAnalyticsService";
import DOMService from "@/services/DOMService";
import * as Sentry from "@sentry/browser";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { Country, Environment } from "@/enums/Config";

jest.mock("@/services/MyRentalsConfig");

describe("GoogleService", () => {
  beforeEach(() => {
    jest.restoreAllMocks();
    spyOn(Sentry, "captureException");
    window.dataLayer = [];
  });

  it("Should return a google analytics service when called", () => {
    //given
    const googleService = new GoogleService();
    //when
    const analytics = googleService.analytics();
    //then
    expect(analytics).toBeInstanceOf(GoogleAnalyticsService);
  });

  it.each([
    {
      country: Country.PT,
      id: "5H4FMQ9",
    },
    {
      country: Country.IT,
      id: "N232XX7",
    },
    {
      country: Country.ES,
      id: "K929ZZR",
    },
  ])(
    "Should successfully create the TagManager and debug it when debug is set to true for every country",
    async (params) => {
      //given
      const googleService = new GoogleService();
      spyOn(DOMService, "loadJS").and.callFake((id, file, callback) => {
        callback();
      });
      spyOn(MyRentalsConfig, "country").and.returnValue(params.country);
      spyOn(MyRentalsConfig, "env").and.returnValue({
        TRACK_EVENTS: "true",
        ENVIRONMENT: Environment.LOCAL,
      });
      spyOn(console, "info");

      //when
      await googleService.init();

      const id = `h-gtm-${params.country}`;
      const file = `https://www.googletagmanager.com/gtm.js?id=GTM-${params.id}`;
      //then
      expect(DOMService.loadJS).toHaveBeenCalledWith(
        id,
        file,
        expect.any(Function),
        expect.any(Function)
      );
      expect(console.info).toBeCalledWith(`[Google] Init ${params.country}`);
      expect(console.info).toBeCalledWith(
        `[Google] TagManagerLoaded GTM-${params.id}`
      );

      expect(window.dataLayer).toEqual([
        {
          event: "gtm.js",
          "gtm.start": expect.any(Number),
          project: "myh",
        },
      ]);
    }
  );

  it("Should successfully create the TagManager and not debug it when debug is set to false", async () => {
    //given
    const googleService = new GoogleService();
    spyOn(DOMService, "loadJS").and.callFake((id, file, callback) => {
      callback();
    });
    spyOn(console, "info");

    spyOn(MyRentalsConfig, "env").and.returnValue({
      TRACK_EVENTS: "false",
      ENVIRONMENT: Environment.LOCAL,
    });

    //when
    await googleService.init();

    const id = "h-gtm-es";
    const file = "https://www.googletagmanager.com/gtm.js?id=GTM-K929ZZR";
    //then
    expect(DOMService.loadJS).toHaveBeenCalledWith(
      id,
      file,
      expect.any(Function),
      expect.any(Function)
    );
    expect(console.info).toBeCalledWith("[Google] Init es");
    expect(console.info).toBeCalledWith(
      "[Google] TagManagerLoaded GTM-K929ZZR"
    );

    expect(window.dataLayer).toEqual([
      {
        event: "gtm.js",
        "gtm.start": expect.any(Number),
        project: "myh",
      },
    ]);
  });

  it("should set the data layer to an empty array when the creation of googletagmanager fails", async () => {
    // given
    spyOn(DOMService, "loadJS").and.callFake((id, file, callback, error) => {
      error();
    });
    const googleService = new GoogleService();

    // when
    await googleService.init();

    // then
    expect(window.dataLayer).toEqual([]);
  });

  it("should set data layer to an empty array if there is no datalayer", async () => {
    // given
    (window.dataLayer as any) = null;

    const googleService = new GoogleService();

    // when
    await googleService.init();

    // then
    expect(window.dataLayer).toEqual([]);
  });

  it("should not console log anything if enviroment is production", async () => {
    spyOn(MyRentalsConfig, "env").and.returnValue({
      TRACK_EVENTS: "true",
      ENVIRONMENT: Environment.PROD,
    });
    spyOn(console, "info");
    spyOn(console, "error");
    const googleService = new GoogleService();

    // when
    await googleService.init();
    // then

    expect(console.info).not.toHaveBeenCalled();
    expect(console.error).not.toHaveBeenCalled();
  });
});
